html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  font-weight: 400;
  color: #667E84;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313F40; 
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5A7679;
  border-radius: 12px;
  width: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #354A50;
}

/* override calendar styles */

.secondary .MuiPickersDay-daySelected {
  background-color: rgba(226, 96, 96, 0.34);
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #273536 !important;
}


.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}